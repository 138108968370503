.w-100[data-v-f62fc058] {
  width: 100px;
}
.w-160[data-v-f62fc058] {
  width: 160px;
}
.text-ellipsis[data-v-f62fc058] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}