.hidden[data-v-1ef06435] {
  position: absolute;
  height: 0;
  overflow: hidden;
}
.textarea-info[data-v-1ef06435] {
  display: block;
  width: 100%;
  height: 0;
  box-sizing: border-box;
  opacity: 0;
}
#CameraCtl[data-v-1ef06435] {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}
.loading-box[data-v-1ef06435] {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  box-sizing: border-box;
}
.loading-svg[data-v-1ef06435] {
  width: 20px;
  height: 20px;
}
.ocr-img-container[data-v-1ef06435] {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
#CameraPhoto[data-v-1ef06435] {
  display: block;
}
.take-photo-btn[data-v-1ef06435] {
  margin: auto;
  width: 300px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
.take-photo-btn .camera-icon[data-v-1ef06435] {
  margin-right: 5px;
  font-size: 16px;
}
.img-result[data-v-1ef06435] {
  position: relative;
  width: 350px;
}
.img-result .result-box[data-v-1ef06435] {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  row-gap: 8px;
  -moz-column-gap: 10px;
       column-gap: 10px;
  margin: 10px 0px;
  height: calc(100% - 120px);
  overflow-y: auto;
}
.img-result .result-box .img-item[data-v-1ef06435] {
  position: relative;
  cursor: pointer;
}
.img-result .result-box .img-item .close-icon[data-v-1ef06435] {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.img-result .result-box .img-item .close-icon.is-upload[data-v-1ef06435] {
  color: var(--themeColor, #17a2b8);
}
.upload-btn[data-v-1ef06435] {
  width: 100%;
}
[data-v-1ef06435] .el-select {
  margin: 0;
  width: 200px;
  margin-bottom: 10px;
}
.save-url[data-v-1ef06435] {
  margin-top: 10px;
}
.view-icon-box[data-v-1ef06435] {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.view-icon-box i[data-v-1ef06435] {
  color: #fff;
}